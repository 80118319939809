import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { discount } from "../../utils/discount";
import currency from "currency-formatter";
import { decQuantity, incQuantity, removeItem } from "../../store/reducers/cartReducer";

const ItemCard = ({ item }) => {
  const dispatch = useDispatch();
  return (
    <div className="w-full border p-4 lg:grid lg:grid-cols-6 lg:gap-4">
      {/* Desktop View */}
      <div className="hidden lg:flex lg:col-span-2 items-center gap-4">
        <img className="w-28 h-28" src={`/images/${item.images[0]}`} alt="productImage" />
        <h4 className="font-titleFont fontstyleclass text-lg">{item.title}</h4>
      </div>

      <div className="hidden lg:flex lg:col-span-4 items-center justify-between gap-6">
        <span className="fontstyleclass">{item.size}</span>
        <span className="fontstyleclass">
          {currency.format(item?.shipping, { code: "INR" })}
        </span>
        <div className="text-lg fontstyleclass">
          {currency.format(discount(item.price, item.discount), { code: "INR" })}
        </div>
        <div className="flex items-center gap-6 text-lg">
          <button
            onClick={() => dispatch(decQuantity(item._id))}
            className="w-8 h-8 text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300"
          >
            -
          </button>
          <p className="mb-0">{item.quantity}</p>
          <button
            onClick={() => dispatch(incQuantity(item._id))}
            className="w-8 h-8 text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300"
          >
            +
          </button>
        </div>
        <div className="font-titleFont font-bold text-lg">
          {currency.format(
            Number(item.quantity * discount(item.price, item.discount)) +
              Number(item.shipping || 0),
            { code: "INR" }
          )}
        </div>
        <ImCross
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this item?")) {
              dispatch(removeItem(item._id));
            }
          }}
          className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
        />
      </div>

      {/* Mobile View */}
      <div className="lg:hidden flex flex-col gap-4 bg-white shadow-md rounded-lg p-4">
        <div className="flex items-center gap-4">
          <img className="w-20 h-20 rounded-lg" src={`/images/${item.images[0]}`} alt="productImage" />
          <div>
            <h4 className="font-titleFont fontstyleclass text-lg">{item.title}</h4>
            <p className="text-sm text-gray-500">{item.size}</p>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <p className="text-sm fontstyleclass">
            Shipping:{" "}
            {currency.format(item?.shipping, {
              code: "INR",
            })}
          </p>
          <p className="text-lg fontstyleclass font-bold">
            {currency.format(
              discount(item.price, item.discount),
              {
                code: "INR",
              }
            )}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button
              onClick={() => dispatch(decQuantity(item._id))}
              className="w-8 h-8 text-lg flex items-center justify-center border border-gray-300 rounded-lg"
            >
              -
            </button>
            <p>{item.quantity}</p>
            <button
              onClick={() => dispatch(incQuantity(item._id))}
              className="w-8 h-8 text-lg flex items-center justify-center border border-gray-300 rounded-lg"
            >
              +
            </button>
          </div>
          <ImCross
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this item?")) {
                dispatch(removeItem(item._id));
              }
            }}
            className="text-red-500 cursor-pointer text-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default ItemCard;

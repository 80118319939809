import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const informationService = createApi({
    reducerPath: 'information',
    tagTypes: 'information',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://thebookwale.com/api/', // Change this to your actual API URL
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            console.log(token);
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createInformation: builder.mutation({
                query: (data) => {
                    return {
                        url: '/create-information',
                        method: 'POST',
                        body: data
                    }
                },
                invalidatesTags: ['information']
            }),
            updateInformation: builder.mutation({
                query: ({id, data}) => {
                    return {
                        url: `/update-information/${id}`,
                        method: 'PUT',
                        body: data
                    }
                },
                invalidatesTags: ['information']
            }),
            deleteInformation: builder.mutation({
                query: id => {
                    return {
                        url: `/delete-information/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ['information']
            }),
            getInformation: builder.query({
                query: () => {
                    return {
                        url: '/information',
                        method: 'GET'
                    }
                },
                providesTags: ['information']
            }),
            getInformationUsers: builder.query({
                query: () => {
                    return {
                        url: '/informationusers',
                        method: 'GET'
                    }
                },
                providesTags: ['information']
            }),
            getSingleInformation: builder.query({
                query: id => {
                    return {
                        url: `/information/${id}`,
                        method: 'GET'
                    }
                },
                providesTags: ['information']
            }),
            updateInformationStatus: builder.mutation({ // Add the updateStatus mutation
                query: ({ id, status }) => ({
                    url: `/update-status-information/${id}`,
                    method: 'PUT',
                    body: { status }
                }),
                invalidatesTags: ['information']
            })
        }
    }
});

export const {
    useCreateInformationMutation,
    useUpdateInformationMutation,
    useDeleteInformationMutation,
    useGetInformationQuery,
    useGetInformationUsersQuery,
    useGetSingleInformationQuery,
    useUpdateInformationStatusMutation
} = informationService;

export default informationService;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const authService = createApi({
    reducerPath: 'auth',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://thebookwale.com/api/',
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
       return {
           authLogin: builder.mutation({
               query: (loginData) => {
                   return {
                       url: 'login',
                       method: 'POST',
                       body: loginData
                   }
               }
           }),
           userRegister: builder.mutation({
            query: data => {
                return {
                    url: '/register',
                    method: 'POST',
                    body: data
                }
            }
           }),
           userLogin: builder.mutation({
            query: loginData => {
                return {
                    url: '/login',
                    method: 'POST',
                    body: loginData
                }
            }
           }),
           changePassword: builder.mutation({
            query: ({ oldPassword, newPassword,userId }) => ({
                url: '/change-password',
                method: 'PUT',
                body: { oldPassword, newPassword,userId }
            })
        }),
        getUserById: builder.query({
            query: (userId) => ({
                url: `/user/${userId}`,
                method: 'GET'
            })
        }),
        updateProfile: builder.mutation({
            query: ({ name, email, userId }) => ({
                url: '/update-profile',
                method: 'PUT',
                body: { name, email, userId }
            })
        }),
        getAllUsers: builder.query({
            query: () => ({
                url: '/getall_users',
                method: 'GET'
            })
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `/user/${userId}`,
                method: 'DELETE'
            })
        }),
        updateLogo: builder.mutation({
            query: (logoData) => ({
              url: "/logos",
              method: "POST",
              body: logoData,
            }),
          }),

      
    
       }
    }
});
export const {useAuthLoginMutation, useUserRegisterMutation, useUserLoginMutation,useUpdateProfileMutation,useGetUserByIdQuery,useChangePasswordMutation,useGetAllUsersQuery,useDeleteUserMutation,useUpdateLogoMutation,} = authService
export default authService
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const newsletterSubscriptionService = createApi({
    reducerPath: 'newsletterSubscription',
    tagTypes: 'newsletterSubscription',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://thebookwale.com/api/', // Change this to your actual API URL
        prepareHeaders: (headers, { getState }) => {
            // Add any authentication headers if required
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            addSubscription: builder.mutation({
                query: (data) => {
                    return {
                        url: '/subscribe',
                        method: 'POST',
                        body: data
                    }
                },
                invalidatesTags: ['newsletterSubscription']
            }),
            deleteSubscription: builder.mutation({
                query: (id) => {
                    return {
                        url: `/unsubscribe/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ['newsletterSubscription']
            }),
            getAllSubscriptions: builder.query({
                query: () => {
                    return {
                        url: '/subscriptions',
                        method: 'GET'
                    }
                }
            }),
            getSubscriptionById: builder.query({
                query: (id) => {
                    return {
                        url: `/subscription/${id}`,
                        method: 'GET'
                    }
                }
            }),
        }
    }
});

export const {
    useAddSubscriptionMutation,
    useDeleteSubscriptionMutation,
    useGetAllSubscriptionsQuery,
    useGetSubscriptionByIdQuery
} = newsletterSubscriptionService;

export default newsletterSubscriptionService;

import { createSlice } from "@reduxjs/toolkit";
import { discount } from "../../utils/discount";

const cartData = localStorage.getItem("cart");
const cartArray = cartData ? JSON.parse(cartData) : [];

function calculateItems(cart) {
  return cart.reduce((total, item) => total + item.quantity, 0);
}

function calculateTotal(cart) {
  return cart.reduce(
    (total, item) =>
      total + discount(item.price, item.discount) * item.quantity,
    0
  );
}

function calculateTotalShipping(cart) {
  return cart.reduce((total, item) => total + (item.shipping || 0), 0);
}

const cartReducer = createSlice({
  name: "cart",
  initialState: {
    cart: cartArray,
    items: calculateItems(cartArray),
    total: calculateTotal(cartArray),
    totalshipping: calculateTotalShipping(cartArray),
  },
  reducers: {
    addCart: (state, { payload }) => {
      state.cart.push(payload);
      state.items = calculateItems(state.cart);
      state.total = calculateTotal(state.cart);
      state.totalshipping = calculateTotalShipping(state.cart);
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    incQuantity: (state, { payload }) => {
      const find = state.cart.find((item) => item._id === payload);
      if (find) {
        find.quantity += 1;
        state.items = calculateItems(state.cart);
        state.total = calculateTotal(state.cart);
        state.totalshipping = calculateTotalShipping(state.cart);
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },
    decQuantity: (state, { payload }) => {
      const find = state.cart.find((item) => item._id === payload);
      if (find && find.quantity > 1) {
        find.quantity -= 1;
        state.items = calculateItems(state.cart);
        state.total = calculateTotal(state.cart);
        state.totalshipping = calculateTotalShipping(state.cart);
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },
    removeItem: (state, { payload }) => {
      state.cart = state.cart.filter((item) => item._id !== payload);
      state.items = calculateItems(state.cart);
      state.total = calculateTotal(state.cart);
      state.totalshipping = calculateTotalShipping(state.cart);
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    emptyCart: (state) => {
      state.cart = [];
      state.items = 0;
      state.total = 0;
      state.totalshipping = 0;
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
  },
});

export const { addCart, incQuantity, decQuantity, removeItem, emptyCart } =
  cartReducer.actions;
export default cartReducer.reducer;

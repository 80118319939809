import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TwitterPicker } from "react-color";
import { v4 as uuidv4 } from 'uuid';
import ReactQuill from "react-quill";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import { useCProductMutation } from "../../store/services/productService";
import Spinner from "../../components/Spinner";
import Colors from "../../components/Colors";
import SizesList from "../../components/SizesList";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useGetBrandsQuery } from "../../store/services/brandService";
import { useCreateSizeMutation, useGetAllSizesQuery, useRemoveSizeMutation } from "../../store/services/sizeService";
import { AiOutlineDelete } from 'react-icons/ai';
import { useAllProductTypesQuery } from "../../store/services/productTypeService";

const CreateProduct = () => {
    const { data: allsizes, error, isLoading } = useGetAllSizesQuery();
    const [createSize] = useCreateSizeMutation();
    const [removeSize] = useRemoveSizeMutation();
    
    const [sizes, setSizes] = useState([
        { name: '26', price: 0 }, { name: '28', price: 0 }, { name: '30', price: 0 },
        { name: '32', price: 0 }, { name: '34', price: 0 }, { name: '36', price: 0 },
        { name: '38', price: 0 }, { name: '40', price: 0 }, { name: '42', price: 0 },
        { name: '44', price: 0 }
    ]);

    useEffect(() => {
        if (allsizes) {
            setSizes(allsizes.map(size => ({ name: size.name, _id: size._id, price: 0 })));
        }
    }, [allsizes]);

    const { data = [], isFetching } = useAllCategoriesQuery();
    const { data: allbrands = [], isFetching: fetchingbrands } = useGetBrandsQuery();
    const { data: allproducttypes = [], isFetching: fetchingtypes } = useAllProductTypesQuery();
    const [value, setValue] = useState('');
    const [state, setState] = useState({
        title: '',
        discount: 0,
        stock: 100,
        category: '',
        colors: [],
        shipping: 0,
        shipdetails: '',
        brandName: '',
        type: '',       // Added type
        class: '',  
        productType:'',
        board: ''   
    });

    const [sizeList, setSizeList] = useState([]);
    const [customSize, setCustomSize] = useState('');
    const [images, setImages] = useState([]);
    const [preview, setPreview] = useState([]);

    const handleInput = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleSizePriceChange = (sizeName, price) => {
        setSizeList(sizeList.map(size => size.name === sizeName ? { ...size, price: parseFloat(price) } : size));
    };

    const saveColors = (color) => {
        const filtered = state.colors.filter((clr) => clr.color !== color.hex);
        setState({ ...state, colors: [...filtered, { color: color.hex, id: uuidv4() }] });
    };

    const deleteColor = color => {
        const filtered = state.colors.filter(clr => clr.color !== color.color);
        setState({ ...state, colors: filtered });
    };

    const chooseSize = sizeObject => {
        if (!sizeList.find(size => size.name === sizeObject.name)) {
            setSizeList([...sizeList, { ...sizeObject, price: 0 }]);
        }
    };

    const deleteSize = name => {
        const filtered = sizeList.filter(size => size.name !== name);
        setSizeList(filtered);
    };

    const handleCustomSizeChange = e => {
        setCustomSize(e.target.value);
    };

    const addCustomSize = async () => {
        const sizeData = { name: customSize };
        await createSize(sizeData);
        if (customSize && !sizeList.find(size => size.name === customSize)) {
            setSizeList([...sizeList, { name: customSize, price: 0 }]);
            setCustomSize('');
        } else {
            toast.error('Size already exists or is invalid');
        }
    };

    const handleImageChange = e => {
        const files = Array.from(e.target.files);
        setImages(files);

        const filePreviews = files.map(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise(resolve => {
                reader.onloadend = () => resolve(reader.result);
            });
        });

        Promise.all(filePreviews).then(previews => setPreview(previews));
    };

    const [createNewProduct, response] = useCProductMutation();
    const createPro = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('sizes', JSON.stringify(sizeList));
        formData.append('description', value);
        images.forEach((image, index) => {
            formData.append('images', image);
        });
        createNewProduct(formData);
    };

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map(err => {
                toast.error(err.msg);
            });
        }
    }, [response?.error?.data?.errors]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(response?.data?.msg));
            navigate('/dashboard/products');
        }
    }, [response?.isSuccess]);

    return (
        <Wrapper>
            <ScreenHeader>
                <Link to="/dashboard/products" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i> products list
                </Link>
            </ScreenHeader>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3">
                <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="title" className="label">title</label>
                            <input type="text" name="title" className="form-control" id="title" placeholder="title..." onChange={handleInput} value={state.title} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="discount" className="label">discount</label>
                            <input type="number" name="discount" className="form-control" id="discount" placeholder="discount..." onChange={handleInput} value={state.discount} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="shipping" className="label">Shipping Charges</label>
                            <input type="number" name="shipping" className="form-control" id="shipping" placeholder="Shipping Charges..." onChange={handleInput} value={state.shipping} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="categories" className="label">categories</label>
                            {!isFetching ? data?.categories?.length > 0 && <select name="category" id="categories" className="form-control" onChange={handleInput} value={state.category}>
                                <option value="">Choose category</option>
                                {data?.categories?.map(category => (
                                    <option value={category.name} key={category._id}>{category.name}</option>
                                ))}
                            </select> : <Spinner />}
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="producttype" className="label">Product Tyep</label>
                            {!fetchingtypes ? allproducttypes?.productTypes.length > 0 && <select name="productType" id="productType" className="form-control" onChange={handleInput} value={state.productType}>
                                <option value="">Choose Product Type</option>
                                {allproducttypes?.productTypes.map(brand => (
                                    <option value={brand._id} key={brand._id}>{brand.name}</option>
                                ))}
                            </select> : <Spinner />}
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="brandName" className="label">Brand Name</label>
                            {!fetchingtypes ? allbrands?.brands?.length > 0 && <select name="brandName" id="brandName" className="form-control" onChange={handleInput} value={state.brandName}>
                                <option value="">Choose Brand</option>
                                {allbrands?.brands?.map(brand => (
                                    <option value={brand.slug} key={brand._id}>{brand.name}</option>
                                ))}
                            </select> : <Spinner />}
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="type" className="label">Type</label>
                            <select name="type" id="type" className="form-control" onChange={handleInput} value={state.type}>
                                <option value="">Select type</option>
                                <option value="summer">Summer</option>
                                <option value="winter">Winter</option>
                                <option value="both">Both</option>
                            </select>
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="class" className="label">Class</label>
                            <select
                                name="class"
                                id="class"
                                className="form-control"
                                onChange={handleInput}
                                value={state.class}
                            >
                                <option value="">Select class</option>
                                <option value="All">All</option> {/* Option for "All" */}
                                <option value="KG">KG</option>
                                <option value="Nursery">Nursery</option>
                                <option value="L-KG">L-KG</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                            </div>
                            <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="board" className="label">Board</label>
                            <select
                                name="board"
                                id="board"
                                className="form-control"
                                onChange={handleInput}
                                value={state.board}
                            >
                                <option value="">Select board</option>
                                <option value="BOTH">BOTH</option> {/* Option for "BOTH" */}
                                <option value="CBSE">CBSE</option>
                                <option value="ICSE">ICSE</option>
                            </select>
                            </div>
                        <div className="w-full p-3">
                            <label htmlFor="sizes" className="label">choose sizes</label>
                            {sizes.length > 0 && (
                                <div className="flex flex-wrap -mx-3">
                                    {sizes.map((size) => (
                                        <div key={size._id} className="size flex items-center mb-2">
                                            <div onClick={() => chooseSize(size)} className="mr-2">{size.name}</div>
                                            <AiOutlineDelete
                                                className="cursor-pointer text-red-500"
                                                onClick={async () => await removeSize(size._id)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="flex items-center mt-3 md:w-6/12 p-3">
                                <input
                                    type="text"
                                    value={customSize}
                                    onChange={handleCustomSizeChange}
                                    placeholder="Enter custom size"
                                    className="form-control"
                                />
                                <button type="button" onClick={addCustomSize} className="btn btn-indigo ml-2">Add</button>
                            </div>
                        </div>
                        <div className="w-full p-3">
                            {sizeList.map((size, index) => (
                                <div key={index} className="flex items-center mb-2">
                                    <span className="mr-2">{size.name}</span>
                                    <input
                                        type="number"
                                        className="form-control mr-2"
                                        placeholder="Enter price for size"
                                        value={size.price}
                                        onChange={e => handleSizePriceChange(size.name, e.target.value)}
                                    />
                                    <AiOutlineDelete
                                        className="cursor-pointer text-red-500"
                                        onClick={() => deleteSize(size.name)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="images" className="label">Upload Images</label>
                            <input type="file" name="images" id="images" className="input-file" onChange={handleImageChange} multiple />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="description" className="label">Description</label>
                            <ReactQuill theme="snow" id="description" value={value} onChange={setValue} placeholder="Description..." />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="shipdetails" className="label">Shipping Instructions</label>
                            <ReactQuill theme="snow" id="shipdetails" value={state.shipdetails} onChange={(e) => setState({ ...state, shipdetails: e })} placeholder="Shipping Instructions..." />
                        </div>
                        <div className="w-full p-3">
                            <input type="submit" value={response.isLoading ? 'loading...' : 'save product'} disabled={response.isLoading} className="btn btn-indigo" />
                        </div>
                    </div>
                </form>
                <div className="w-full xl:w-4/12 p-3">
                    <Colors colors={state.colors} deleteColor={deleteColor} />
                    <SizesList list={sizeList} deleteSize={deleteSize} />
                    {preview.map((url, index) => (
                        <ImagesPreview key={index} url={url} heading={`Image ${index + 1}`} />
                    ))}
                </div>
            </div>
        </Wrapper>
    );
};

export default CreateProduct;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const productTypeService = createApi({
  reducerPath: "productType",
  tagTypes: ["productTypes"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://thebookwale.com/api/",
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      console.log(token);
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createProductType: builder.mutation({
        query: (name) => {
          return {
            url: "create-product-type",
            method: "POST",
            body: name,
          };
        },
        invalidatesTags: ["productTypes"],
      }),
      updateProductType: builder.mutation({
        query: ({ id, data }) => {
          return {
            url: `update-product-type/${id}`,
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: ["productTypes"],
      }),
      deleteProductType: builder.mutation({
        query: (id) => {
          return {
            url: `delete-product-type/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["productTypes"],
      }),
      getProductTypes: builder.query({
        query: (page) => {
          return {
            url: `product-types/${page}`,
            method: "GET",
          };
        },
        providesTags: ["productTypes"],
      }),
      fetchProductType: builder.query({
        query: (id) => {
          return {
            url: `fetch-product-type/${id}`,
            method: "GET",
          };
        },
        providesTags: ["productTypes"],
      }),
      allProductTypes: builder.query({
        query: () => {
          return {
            url: "all-product-types",
            method: "GET",
          };
        },
        providesTags: ["productTypes"],
      }),
    };
  },
});

export const {
  useCreateProductTypeMutation,
  useGetProductTypesQuery,
  useFetchProductTypeQuery,
  useAllProductTypesQuery,
  useUpdateProductTypeMutation,
  useDeleteProductTypeMutation,
} = productTypeService;
export default productTypeService;
